import React from 'react';
import {useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {Box, Fade, Grid, Typography} from "@mui/material";
import {routingApiHandlerFetch} from "src/Handler/RoutingApiHandler";
import TextFieldComponent from "src/Component/TextFieldComponent";
import ButtonComponent from "src/Component/ButtonComponent";
import {formHandlerInit} from "src/Handler/FormHandler";
import {snackbarReduxOpen} from "src/Redux/snackbarRedux";
import SelectComponent from "src/Component/SelectComponent";
import {color} from "src/Config/Theme";
import {Folder} from "@mui/icons-material";
import SoftwareBackupVolumeComponent from "./SoftwareBackupVolumeComponent";
import ShadowBoxComponent from "src/Component/ShadowBoxComponent";

function SoftwareTabVolumeComponent(props) {
    // React
    const [loading, setLoading] = React.useState(false);
    const [optionServers, setOptionServers] = React.useState([]);

    // Router
    const {software} = useParams();

    // Redux
    const dispatch = useDispatch();

    // Form
    const [form, setForm] = React.useState({
        volumePath: {
            id: 'volumePath',
            label: 'Chemin',
            helperText: 'Entrez le chemin du volume.',
            type: 'text',
            value: '/makersystem/',
            options: {validation: ['required']}
        },
        volumeFile: {
            id: 'volumeFile',
            label: 'Dossier',
            helperText: 'Entrez le nom du dossier du volume.',
            type: 'text',
            value: '',
            options: {validation: ['required']}
        },
        backupVolumeServer: {
            id: 'backupVolumeServer',
            label: 'Serveur de backup',
            helperText: 'Choisissez le serveur de backup.',
            type: 'text',
            value: '',
            options: {validation: ['required']}
        },
        backupVolumeFrequency: {
            id: 'backupVolumeFrequency',
            label: 'Fréquence',
            helperText: 'Entrez la fréquence de sauvegarder automatique en heure.',
            type: 'text',
            value: '',
            options: {validation: ['required']}
        },
        backupVolumeMaxDay: {
            id: 'backupVolumeMaxDay',
            label: 'Nombre de jour',
            helperText: 'Entrez le nombre de jour maximum avant d\'archiver les sauvegardes.',
            type: 'integer',
            value: '',
            options: {validation: ['required']}
        },
        backupVolumeMaxNumber: {
            id: 'backupVolumeMaxNumber',
            label: 'Nombre de sauvegarde',
            helperText: 'Entrez le nombre de sauvegarde maximum.',
            type: 'integer',
            value: '',
            options: {validation: ['required']}
        },
    });
    const handler = formHandlerInit(form, setForm);
    const save = () => {
        if (handler.checkValid() < 1) {
            handler.setLoading(true);
            setLoading(true);

            routingApiHandlerFetch({
                route: 'api_administrator_backup_volume',
                method: 'POST',
                params: {software: software ?? ''},
                data: handler.getDataApi(),
                success: () => {
                    handler.setLoading(false);
                    setLoading(false);
                    dispatch(snackbarReduxOpen({text: 'Information de backup des volumes enregistrées avec succès.', variant: 'success'}))
                },
                error: (response) => {
                    if (response.code === 400) {
                        handler.setErrorApi(response.error);
                    }
                    else {
                        dispatch(snackbarReduxOpen({text: 'Oups ! Une erreur inattendue s\'est produite.', variant: 'error'}))
                    }

                    handler.setLoading(false);
                    setLoading(false);
                }
            });
        }
    };

    // Bar + Info
    React.useEffect(() => {
        if (props.software) {
            handler.setDataApi(props.software);
        }
    }, [props.software]);
    React.useEffect(() => {
        routingApiHandlerFetch({
            route: 'api_administrator_software_enum_servers',
            method: 'GET',
            success: (response) => {
                setOptionServers(response.data);
            },
            error: () => {
                dispatch(snackbarReduxOpen({text: 'Oups ! Une erreur inattendue s\'est produite.', variant: 'error'}))
            }
        });
    }, []);

    return (<Box>
            <Fade in={true} {...{timeout: 500}}>
                <Box>
                    <ShadowBoxComponent sx={{width: '100%', padding: 0, marginBottom: '16px'}}>
                        <Box sx={{width: '100%', height: '100%', padding: '12px', boxSizing: 'border-box'}}>
                            <Typography sx={{color: color.textPrimary, fontWeight: '600', marginBottom: '6px'}}>
                                <Folder sx={{verticalAlign: 'bottom', marginRight: '10px', margin: '6px 10px 0 18px'}}/> Volume
                            </Typography>

                            <br/>

                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <TextFieldComponent handler={handler} id={'volumePath'} onSubmit={save}/>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <TextFieldComponent handler={handler} id={'volumeFile'} onSubmit={save}/>
                                </Grid>
                                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                    <SelectComponent handler={handler} id={'backupVolumeServer'} onSubmit={save} options={optionServers}/>
                                </Grid>
                                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                    <TextFieldComponent handler={handler} id={'backupVolumeMaxDay'} onSubmit={save}/>
                                </Grid>
                                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                    <TextFieldComponent handler={handler} id={'backupVolumeMaxNumber'} onSubmit={save}/>
                                </Grid>
                                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                    <TextFieldComponent handler={handler} id={'backupVolumeFrequency'} onSubmit={save}/>
                                </Grid>
                            </Grid>

                            <br/>

                            <ButtonComponent label={'Enregistrer'} onClick={save} loading={loading}/>
                        </Box>
                    </ShadowBoxComponent>
                </Box>
            </Fade>

            <SoftwareBackupVolumeComponent setSoftware={props.setSoftware} software={props.software}/>
        </Box>
    );
}

export default SoftwareTabVolumeComponent;
