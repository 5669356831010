import React from 'react';
import {Box, Fade} from "@mui/material";
import TabsComponent from "src/Component/TabsComponent";
import ServerTabGeneralComponent from "./ServerTabGeneralComponent";
import {Dvr, CallSplit, Security, VpnLock, Code, Coronavirus} from "@mui/icons-material";
import ServerTabFirewallComponent from "./ServerTabFirewallComponent";
import ServerTabReverseProxyComponent from "./ServerTabReverseProxyComponent";
import ServerExtensionComponent from "./ServerExtensionComponent";
import ServerSSHComponent from "./ServerSSHComponent";
import ServerTabAntimalwareComponent from "./ServerTabAntimalwareComponent";
import ServerTabSecurityComponent from "./ServerTabSecurityComponent";

function ServerTabsComponent(props) {
    const getTabsAndContent = () => {
        const commonTabs = [
            {
                key: 'information',
                label: (
                    <span>
                        <Dvr sx={{ verticalAlign: 'bottom', fontSize: '20px', margin: '0px 8px -1px 0px' }} />
                        Général
                    </span>
                ),
                component: <ServerTabGeneralComponent server={props.server} setReload={props.setReload}/>
            }
        ];

        if (props.server && props.server.kernel === 'LINUX') {
            return [
                ...commonTabs,
                {
                    key: 'antimalware',
                    label: (
                        <span>
                            <Coronavirus sx={{ verticalAlign: 'bottom', fontSize: '20px', margin: '0px 8px -1px 0px' }} />
                            Anti-malware
                        </span>
                    ),
                    component: <ServerTabAntimalwareComponent server={props.server} />
                },
                {
                    key: 'firewall',
                    label: (
                        <span>
                            <Security sx={{ verticalAlign: 'bottom', fontSize: '20px', margin: '0px 8px -1px 0px' }} />
                            Firewall
                        </span>
                    ),
                    component: <ServerTabFirewallComponent server={props.server} />
                },
                {
                    key: 'reverse-proxy',
                    label: (
                        <span>
                            <CallSplit sx={{ verticalAlign: 'bottom', fontSize: '20px', margin: '0px 8px -1px 0px' }} />
                            Reverse Proxy
                        </span>
                    ),
                    component: <ServerTabReverseProxyComponent server={props.server} />
                }
            ];
        }
        return commonTabs;
    };

    const tabsConfig = getTabsAndContent();

    return (
        <Fade in={true} {...{ timeout: 500 }}>
            <Box sx={{ width: '100%', height: '100%' }}>
                <TabsComponent
                    tabDefault={'information'}
                    tabs={tabsConfig.map(({ key, label }) => ({ key, label }))}
                >
                    {tabsConfig.map(({ key, component }) => (
                        <Box key={key} sx={{ width: '100%', height: '100%' }}>
                            {component}
                        </Box>
                    ))}
                </TabsComponent>
            </Box>
        </Fade>
    );
}


export default ServerTabsComponent;
/*

                    <Box key={'ssh'} sx={{width: '100%', height: '100%'}}>
                        <ServerSSHComponent server={props.server}/>
                    </Box>
                    <Box key={'extension'} sx={{width: '100%', height: '100%'}}>
                        <ServerExtensionComponent server={props.server}/>
                    </Box>
                    <Box key={'vpn'} sx={{width: '100%', height: '100%'}}>
                    </Box>
 */


/*{
    key: 'security', label: <span>
        <Security sx={{verticalAlign: 'bottom', fontSize: '20px', margin: '0px 8px -1px 0px'}}/>
        Sécurité
</span>
},
{
    key: 'ssh', label: <span>
        <Code sx={{verticalAlign: 'bottom', fontSize: '20px', margin: '0px 8px -1px 0px'}}/>
        SSH
</span>
},{
    key: 'antimalware', label: <span>
        <Coronavirus sx={{verticalAlign: 'bottom', fontSize: '20px', margin: '0px 8px -1px 0px'}}/>
        Anti-malware
</span>
},
{
    key: 'extension', label: <span>
        <Extension sx={{verticalAlign: 'bottom', fontSize: '20px', margin: '0px 8px -1px 0px'}}/>
        Extension
</span>
},
{
    key: 'reverse-proxy', label: <span>
        <CallSplit sx={{verticalAlign: 'bottom', fontSize: '20px', margin: '0px 8px -1px 0px'}}/>
        Reverse Proxy
</span>
},
{
    key: 'vpn', label: <span>
        <VpnLock sx={{verticalAlign: 'bottom', fontSize: '20px', margin: '0px 8px -1px 0px'}}/>
        VPN
</span>
}*/
