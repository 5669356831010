import React from 'react';
import {IconButton, Tooltip} from "@mui/material";
import {color} from "src/Config/Theme";
import UseAnimations from 'react-useanimations';

function ButtonIconComponent(props) {
    const style = {
        background: props.background,
        '&:hover': {
            background: props.backgroundHover + ' !important'
        },
    };
    const [tooltipOpen, setTooltipOpen] = React.useState(false);

    return (
        <Tooltip
            title={props.tooltip && !props.disabled ? props.tooltip : ''}
            placement="bottom"
            open={tooltipOpen}
        >
            <IconButton
                onMouseEnter={() => setTooltipOpen(true)}
                onMouseLeave={() => setTooltipOpen(false)}
                disabled={props.disabled}
                size="small"
                sx={{...style, ...(props.sx ? props.sx : {})}}
                onClick={() => {
                    setTooltipOpen(false);
                    if (props.onClick) props.onClick();
                }}
            >
                {
                    props.iconAnimation && props.animation ?
                        <UseAnimations
                            animation={props.iconAnimation}
                            strokeColor={props.color + ' !important'}
                            size={22}
                            autoplay={true}
                            loop={true}
                            className={'animation-download'}
                        /> :
                        <props.icon sx={{
                            color: props.disabled ? color.textDisabled + ' !important' : props.color + ' !important',
                            fontSize: '15px',
                            padding: '2px'
                        }}/>
                }
            </IconButton>
        </Tooltip>
    );
}

export default ButtonIconComponent;
