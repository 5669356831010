import React from 'react';
import {Box, Fade} from "@mui/material";
import ShadowBoxComponent from "src/Component/ShadowBoxComponent";
import {color} from "src/Config/Theme";
import {CallSplit} from "@mui/icons-material";
import ButtonComponent from "src/Component/ButtonComponent";
import {routingApiHandlerFetch} from "src/Handler/RoutingApiHandler";
import 'ace-builds/src-noconflict/mode-ruby';
import 'ace-builds/src-noconflict/theme-solarized_dark';
import {useDispatch} from "react-redux";
import {snackbarReduxOpen} from "src/Redux/snackbarRedux";

function ServerTabReverseProxyComponent(props) {
    // React
    const [loading, setLoading] = React.useState(false);
    const [hasReverseProxy, setHasReverseProxy] = React.useState(false);

    // Redux
    const dispatch = useDispatch();

    const save = () => {
        setLoading(true);

        routingApiHandlerFetch({
            route: 'api_administrator_server_reverse_proxy',
            method: 'POST',
            params: {server: props.server.uuid},
            data: {hasReverseProxy: hasReverseProxy},
            success: () => {
                setLoading(false);
                dispatch(snackbarReduxOpen({text: 'Reverse Proxy enregistré avec succès.', variant: 'success'}));
            },
            error: () => {
                dispatch(snackbarReduxOpen({text: 'Oups ! Une erreur inattendue s\'est produite.', variant: 'error'}))
                setLoading(false);
            }
        });
    };

    React.useEffect(() => {
        setLoading(true);
        if (props.server) {
            routingApiHandlerFetch({
                route: 'api_administrator_server_reverse_proxy',
                params: {server: props.server.uuid},
                method: 'GET',
                success: (response) => {
                    setHasReverseProxy(response.data.hasReverseProxy);
                    setTimeout(() => setLoading(false), 750);
                },
                error: () => {
                    setTimeout(() => setLoading(false), 750);
                }
            });
        }
        else {
            setLoading(false);
        }
    }, [props.server]);

    return (
        <Fade in={true} {...{timeout: 500}}>
            <Box sx={{width: '100%'}}>
                <ShadowBoxComponent>
                    <Box sx={{color: color.textPrimary, fontWeight: '600', marginBottom: '6px'}}>
                        <CallSplit sx={{verticalAlign: 'bottom', marginRight: '10px', margin: '6px 10px 0 18px'}}/> Traefik
                    </Box>

                    <div className="checkbox-wrapper-8" style={{position: 'absolute', top: '15px', right: '25px'}}>
                        <label className="bb8-toggle">
                            <input className="bb8-toggle__checkbox" type="checkbox" defaultChecked={!hasReverseProxy} checked={!hasReverseProxy} onChange={() => setHasReverseProxy(!hasReverseProxy)}/>
                            <div className="bb8-toggle__container">
                                <div className="bb8-toggle__scenery">
                                    <div className="bb8-toggle__star"></div>
                                    <div className="bb8-toggle__star"></div>
                                    <div className="bb8-toggle__star"></div>
                                    <div className="bb8-toggle__star"></div>
                                    <div className="bb8-toggle__star"></div>
                                    <div className="bb8-toggle__star"></div>
                                    <div className="bb8-toggle__star"></div>
                                    <div className="tatto-1"></div>
                                    <div className="tatto-2"></div>
                                    <div className="gomrassen"></div>
                                    <div className="hermes"></div>
                                    <div className="chenini"></div>
                                    <div className="bb8-toggle__cloud"></div>
                                    <div className="bb8-toggle__cloud"></div>
                                    <div className="bb8-toggle__cloud"></div>
                                </div>
                                <div className="bb8">
                                    <div className="bb8__head-container">
                                        <div className="bb8__antenna"></div>
                                        <div className="bb8__antenna"></div>
                                        <div className="bb8__head"></div>
                                    </div>
                                    <div className="bb8__body"></div>
                                </div>
                                <div className="artificial__hidden">
                                    <div className="bb8__shadow"></div>
                                </div>
                            </div>
                        </label>
                    </div>

                    <br/>

                    <ButtonComponent label={'Enregistrer'} onClick={() => save()} loading={loading} sx={{marginBottom: '10px'}}/>
                </ShadowBoxComponent>
            </Box>
        </Fade>
    );
}

export default ServerTabReverseProxyComponent;
