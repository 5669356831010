import React from 'react';
import {Box, Fade} from "@mui/material";
import TabsComponent from "src/Component/TabsComponent";
import SoftwareTabDatabaseComponent from "./SoftwareTabDatabaseComponent";
import ShadowBoxComponent from "src/Component/ShadowBoxComponent";
import SoftwareBackupDatabaseComponent from "./SoftwareBackupDatabaseComponent";
import SoftwareBackupVolumeComponent from "./SoftwareBackupVolumeComponent";
import {Storage, FileCopy, Dvr, Lan} from "@mui/icons-material";
import SoftwareFormComponent from "./SoftwareFormComponent";
import SoftwareTabVolumeComponent from "./SoftwareTabVolumeComponent";

function SoftwareTabsComponent(props) {
    console.log(props.software);

    return (
        <Fade in={true} {...{timeout: 500}}>
            <Box sx={{width: '100%', height: '100%'}}>
                <TabsComponent
                    tabDefault={'information'}
                    tabs={!props.software ? [
                        {
                            key: 'information', label: <span>
                                <Dvr sx={{verticalAlign: 'bottom', fontSize: '20px', margin: '0px 8px -1px 0px'}}/>
                                Général
                        </span>
                        }
                    ] : [
                        {
                            key: 'information', label: <span>
                                <Dvr sx={{verticalAlign: 'bottom', fontSize: '20px', margin: '0px 8px -1px 0px'}}/>
                                Général
                        </span>
                        },
                        {
                            key: 'volume', label: <span>
                                <FileCopy sx={{verticalAlign: 'bottom', fontSize: '20px', margin: '0px 8px -1px 0px'}}/>
                                Volume
                        </span>
                        },
                        {
                            key: 'database', label: <span>
                                <Storage sx={{verticalAlign: 'bottom', fontSize: '20px', margin: '0px 8px -1px 0px'}}/>
                                Base de données
                        </span>
                        },
                        {
                            key: 'service', label: <span>
                                <Lan sx={{verticalAlign: 'bottom', fontSize: '20px', margin: '0px 8px -1px 0px'}}/>
                                Service
                        </span>
                        }
                    ]}
                >
                    <Box key={'information'} sx={{width: '100%', height: '100%'}}>
                        <SoftwareFormComponent setSoftware={props.setSoftware} software={props.software}/>
                    </Box>
                    <Box key={'volume'} sx={{width: '100%', height: '100%'}}>
                        <SoftwareTabVolumeComponent {...props}/>
                    </Box>
                    <Box key={'database'} sx={{width: '100%', height: '100%'}}>
                        <ShadowBoxComponent sx={{width: '100%', padding: 0, marginBottom: '16px'}}>
                            <SoftwareTabDatabaseComponent {...props}/>
                        </ShadowBoxComponent>
                        <SoftwareBackupDatabaseComponent setSoftware={props.setSoftware} software={props.software}/>
                    </Box>
                </TabsComponent>
            </Box>
        </Fade>
    );
}

export default SoftwareTabsComponent;
