import React from 'react'
import {IconButton, InputAdornment, TextField} from "@mui/material";
import {Box} from "@mui/system";
import {VisibilityOff, Visibility} from "@mui/icons-material";
import {color} from "src/Config/Theme";

function TextFieldComponent(props) {
    const [isFocus, setIsFocus] = React.useState(false);
    const [showPassword, setShowPassword] = React.useState(false);
    let input = props.handler.get(props.id);

    const onChange = (event) => {
        props.handler.setError(props.id, '');
        if (input.type === 'float') {
            props.handler.setValue(props.id, event.target.value, true);
        } else {
            props.handler.setValue(props.id, event.target.value);
        }
    };
    const onBlur = () => {
        setIsFocus(false);
        if (input.type === 'float') {
            props.handler.setValue(props.id, input.value);
        }
        props.handler.checkValidBy(props.id);
    };
    const onFocus = () => {
        setIsFocus(true);
    };

    const style = {
        width: '100%',
        '& input': {
            cursor: input.loading ? 'wait' : input.disabled ? 'not-allowed' : 'pointer',
            padding: '10px 14px',
            fontSize: 14,
            color: color.textPrimary,
            WebkitTextFillColor: color.textPrimary + ' !important;',
            caretColor: color.textPrimary
        },
        '& textarea': {
            cursor: input.loading ? 'wait' : input.disabled ? 'not-allowed' : 'pointer',
            padding: '10px 14px',
            fontSize: 14,
            color: color.textPrimary,
            WebkitTextFillColor: color.textPrimary + ' !important;',
            caretColor: color.textPrimary,
            minHeight: '100px'
        },
        '& .MuiOutlinedInput-root': {
            fontSize: 14
        },
        '& .MuiInputLabel-outlined': {
            transform: 'translate(13px, 9px) scale(1)',
            fontSize: 14
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(15px, -7px) scale(0.75)',
        },
        '& .MuiFormHelperText-root': {
            margin: '5px 14px',
            fontSize: 10,
            minHeight: 10,
            lineHeight: '10px',
            color: isFocus ? color.primary + ' !important' : input.error ? color.textError + ' !important' : color.textTertiary + ' !important'
        },
        '&:hover .MuiFormHelperText-root': {
            color: (input.loading || input.disabled) ? color.textTertiary + ' !important' : isFocus ? color.primary + ' !important' : input.error ? color.textError + ' !important' : color.textPrimary + ' !important'
        },
        '& fieldset': {
            borderWidth: '1px !important',
            borderColor: isFocus ? color.primary + ' !important' : input.error ? color.textError + ' !important' : color.textTertiary + ' !important',
            // boxShadow: isFocus ? ('0px 0px 2px 2px ' + color.primary) : 'none'
        },
        '&:hover fieldset': {
            borderColor: (input.loading || input.disabled) ? color.textTertiary + ' !important' : isFocus ? color.primary + ' !important' : input.error ? color.textError + ' !important' : color.textPrimary + ' !important',
        },
        '& label': {
            color: isFocus ? color.primary + ' !important' : input.error ? color.textError + ' !important' : color.textPrimary + ' !important'
        }
    };

    return (
        <Box sx={{width: '100%'}}>
            <TextField
                multiline={props.multiline}
                id={input.id}
                value={input.value}
                disabled={input.disabled || input.loading}
                label={input.label}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
                sx={style}
                variant="outlined"
                autoComplete={(showPassword || input.type !== 'password') ? '' : (props.autoComplete ?? 'current-password')}
                helperText={isFocus ? input.helperText : input.error ? input.error : (input.helperText + ((input.options && input.options.validation && input.options.validation.indexOf('required') > -1) ? '' : ' (Optionnel)'))}
                type={(showPassword || input.type !== 'password') ? 'text' : 'password'}
                onKeyPress={(e) => {
                    if (e.key === 'Enter' && props.onSubmit) {
                        props.onSubmit();
                    }
                }}
                InputProps={props.adornmentOrientation && props.adornmentOrientation === 'left' ? {
                    startAdornment: input.type === 'password' ? (
                        <InputAdornment position="end" disabled={input.disabled || input.loading}>
                            <IconButton
                                onClick={() => setShowPassword(!showPassword)}
                                edge="end"
                                disabled={input.disabled || input.loading}
                            >
                                {showPassword ? <Visibility sx={{fontSize: 18, color: color.textPrimary}}/> : <VisibilityOff sx={{fontSize: 18, color: color.textPrimary}}/>}
                            </IconButton>
                        </InputAdornment>
                    ) : props.adornment ? props.adornment : <></>
                } : {
                    endAdornment: input.type === 'password' ? (
                        <InputAdornment position="end" disabled={input.disabled || input.loading}>
                            <IconButton
                                onClick={() => setShowPassword(!showPassword)}
                                edge="end"
                                disabled={input.disabled || input.loading}
                            >
                                {showPassword ? <Visibility sx={{fontSize: 18, color: color.textPrimary}}/> : <VisibilityOff sx={{fontSize: 18, color: color.textPrimary}}/>}
                            </IconButton>
                        </InputAdornment>
                    ) : props.adornment ? props.adornment : <></>
                }}
            />
        </Box>
    )
}

export default TextFieldComponent;
